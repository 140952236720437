import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeTitle, changeTitleSEO, changeSubmenu, generalSelector} from "../store/reducers/generalReducer";
import {useSSE} from "use-sse";

const ApplyForFundingPage = ()=>{
    const generalSettings = useSelector(generalSelector);
    const {title} = generalSettings;
    const dispatch = useDispatch()

    useSSE(()=>{
        dispatch(changeTitle('Innovation at HBKU'))
        dispatch(changeTitleSEO('Office of Innovation and Industrial Relations | Apply for Funding'))
        dispatch(changeSubmenu(''))
        return Promise.resolve('Office of Innovation and Industrial Relations | Apply for Funding')
    },[])

    useEffect(() => {
        document.title = 'Office of Innovation and Industrial Relations | Apply for Funding'

    }, []);

    return (
       <Fragment>
         <section className="m-wrap p-b-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-22">
                    <h3 className="title-md-m text-darkblue text-left"> Required Documentation  </h3>
                    <div className="btn__list m-b-3">
                          
                       
                         <ul className="btn__list_group m-x-0" style={{display:'flex',alignItems:'start',flexDirection:'column'}}>
                        
                            <li className="btn__list_group--item">  <p class="m-0">To apply and access relevant documents, please connect to the HBKU network or use “GlobalProtect”.</p></li>
                               
                            <li className="btn__list_group--item"> <a href="https://qforgqa0.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7BFE5C30DD-6BAA-4CB0-9031-FF8A78478F3E%7D&file=Proposal%20Template%20HBKU%20OIIR.docx&action=default&mobileredirect=true" target="_blank">
                            Proposal Template	</a> </li>
                            <li className="btn__list_group--item"> <a href="https://qforgqa0.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7B2466A710-A3F0-4FE6-9A51-52D3272C0CA2%7D&file=Letter%20of%20Commitment%20HBKU%20OIIR.docx&action=default&mobileredirect=true" target="_blank" > Letter of Commitment</a> </li>
                            <li className="btn__list_group--item"> <a  href="https://qforgqa0.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7BF322C958-E088-42E6-9A37-BAB1161D8A53%7D&file=Innovation%20Funds%20Timeline%20HBKU%20OIIR%20(2).docx&action=default&mobileredirect=true" target="_blank" >Office of Innovation and Industrial Relations Timeline</a> </li>
                            <li className="btn__list_group--item"> <a  href="https://portal.qf.org.qa/Documents/HBKU%20IC/Technology%20Readiness%20Level%20HBKU%20Innovation%20Center.pdf" target="_blank" >Technology Readiness Level Information Card</a> </li>
                            <li className="btn__list_group--item"><a 
                            href="https://qforgqa0.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7B18FB798A-CB4B-4861-BCC9-7EDD332DB6B3%7D&file=Progress%20Report%20Template_HBKU%20OIIR.docx&action=default&mobileredirect=true"
                            target="_blank">Progress Report Template</a></li>
                            <li className="btn__list_group--item"><a 
                            href="https://qforgqa0.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc=%7BE830C863-060F-46CA-90F7-E117D9713C01%7D&file=Final%20Report%20Template_HBKU%20OIIR.docx&action=default&mobileredirect=true"
                            target="_blank">Final Report Template</a></li>
                        </ul>
                    </div>
                        
                    </div>
                    <div className="col-md-26">
                        <h3 className="title-md-m text-darkblue m-b-3 text-left"> Apply Now  </h3>
                        <div className="btn__list">
                            <ul className="btn__list_group m-x-0" style={{display:'flex',alignItems:'start',flexDirection:'column'}}>
                                {/* <li className="btn__list_group--item"> <a target="_blank" href="https://hbku.wufoo.com/forms/z2lkybl00jyzyg/" target="_blank"> HBKU Innovative Start-Up Fund (ISF)</a> </li> */}
                                {/* <li className="btn__list_group--item"> <a className="" target="_blank" href=" https://hbku.wufoo.com/forms/z1kansif0ritayd/" target="_blank">HBKU Student Innovation Fund (SIF) </a> </li> */}
                                {/* <li className="btn__list_group--item"> <a className="" target="_blank" href=" https://hbku.wufoo.com/forms/zabct1t0x1lmql/" target="_blank"> HBKU Industrial Innovation Fund (IIF)</a> </li> */}
                                {/* <li className="btn__list_group--item"> <a className="" target="_blank" href="https://hbku.wufoo.com/forms/z1dcjjr6134qbhx/" target="_blank">HBKU Social Innovation Fund (SOCIF)  </a> </li> */}
                               
                                <li className="btn__list_group--item"> <a className="" href="https://hbku.wufoo.com/forms/hbku-innovation-funds-office-sif-cycle-8/" target="_blank">HBKU Student Innovation Fund (SIF)</a> </li>
                                <li className="btn__list_group--item"> <a className="" href="https://hbku.wufoo.com/forms/hbku-innovation-funds-office-iif-cycle-8/ " target="_blank">HBKU Industrial Innovation Fund (IIF)</a> </li>
                                <li className="btn__list_group--item"> <a className="" href="https://hbku.wufoo.com/forms/hbku-innovation-funds-office-socif-cycle-8/ " target="_blank">HBKU Social Innovation Fund (SOCIF)</a> </li>
                            </ul>
                        </div>
                        <br />
                        
                    </div>

                </div>
            </div>
        </section>
        <hr />
        <section className="s-wrap p-t-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-22">
                        <h3 className="title-md-m text-darkblue m-b-2 m-t-3 text-left"> Closed  </h3>
                        <div className="btn__list">
                            <ul className="btn__list_group m-x-0" style={{display:'flex',alignItems:'start',flexDirection:'column'}}>
                            <li className="btn__list_group--item"> <a className="disabled" href="https://hbku.wufoo.com/forms/z188ll0k0a3ahjw/" target="_blank">Entrepreneurship Program Fund (EPF)  </a> </li>
                            <li className="btn__list_group--item"> <a className="disabled" href="#" target="_blank">Education City Innovative Entrepreneurship Program - Cycle 3</a> </li>
                            {/* <li className="btn__list_group--item"> <a className="disabled" href="https://hbku.wufoo.com/forms/zojq6jh0tovbl1/" target="_blank">Education City Innovative Entrepreneurship Program - Cycle 3</a> </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-26">
                    </div>
                </div>
            </div>
        </section>

       </Fragment>
    )
}


export default ApplyForFundingPage;
