import React from "react";

import FundingItems from "../data/funding-items";
import Accordion from "../blocks/Innovation/Accordion";
import FAQData from "../data/FAQ";

const FundingBlock = ({
    fundingItems = FundingItems
}) =>{
    return (
        <>
            <section className="m-wrap bg-gray6 " data-appear-top-offset="-200" id="section4">
            <div className="services-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-48">
                            <div className="title--info">
                                {/* <h2 className="title-xl m-b-2 text-darkblue " data-id="1">
                                   About </h2> */}
                                <p>In order to catalyze an innovative and entrepreneurial environment and promote collaborative, multidisciplinary, and locally industrially-relevant innovation activities, the HBKU Innovation Funds Office provides the different types of innovation funds.</p>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14 m-b-1">
                                            <div class="l-article">
                                                <div class="video-wrap">
                                                <img class="" src="https://www.hbku.edu.qa/sites/default/files/ibda.png" alt="Office of Innovation and Industrial Relations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-32">
                                            <h2 class="title-md m-b-2 text-darkblue">Innovative Business Discovery and Acceleration Fund (IBDA)</h2>
                                            <p>The Innovative Business Discovery and Acceleration Fund (IBDA) provides HBKU and non-HBKU individuals 
                                                and early-stage startups with financial support and mentorship to transform their nascent ideas into sustainable, 
                                                market-ready businesses with the potential for long-term success.</p>

                                            <div class="l-article">
                                            <ul>
                                                <li> <a href="https://www.hbku.edu.qa/sites/default/files/ibda_proposal_submission_guidelines.pdf" target="_blank">Proposal Submission Guidelines</a> </li>
                                                <li> <a target="_blank" href="https://www.hbku.edu.qa/sites/default/files/ibda_eligibility_and_application_checklist.pdf">Eligibility and Application (checklist)</a> </li>
                                                <li> <a target="_blank" href="https://www.hbku.edu.qa/sites/default/files/ibda_project_plan_template.docx">Project Plan Template</a> </li>
                                                <li> <a target="_blank" href="https://hbku.wufoo.com/forms/ibda-application-form/">Application Form</a> </li>
                                            </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14 m-b-1">
                                            <div class="l-article">
                                                <div class="video-wrap">
                                                <img class="" src="../images/fund-img5.png" alt="Office of Innovation and Industrial Relations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-32">
                                            <h2 class="title-md m-b-2 text-darkblue">Entrepreneurship Program Fund (EPF)
                                            </h2>
                                            <p>The HBKU Entrepreneurship Program Fund (EPF) aims to support Qatar Foundation (QF) entrepreneurs with industry-driven innovations, and to increase local capacity, responsiveness, and private enterprise.
                                            </p>
                                            <p>Application to this fund  is execlusive to entrepreneurs who have attended or are attending capacity building workshops of the Education City Innovative Entrepreneurship Program.</p>

                                            <div class="l-article">
                                            <ul>
                                                <li> <a href="https://portal.qf.org.qa/Documents/HBKU%20IC/Entrepreneurship%20Program%20Fund%20(EPF)%20Guideline%20HBKU%20Innovation%20Center-Final.pdf" target="_blank">Proposal Submission Guidelines</a> </li>
                                                <li> <a target="_blank" href="https://portal.qf.org.qa/Documents/HBKU%20IC/Entrepreneurship%20Program%20Fund%20(EPF)%20eligibility%20and%20checklist%20HBKU%20Innovation%20Center-Final.pdf">Eligibility and Application (checklist)</a> </li>
                                            
                                            </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14 m-b-1">
                                            <div class="l-article">
                                                <div class="video-wrap">
                                                <img class="" src="../images/fund-img2.png"
                                             alt="Office of Innovation and Industrial Relations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-32">
                                        <h2 class="title-md m-b-2 text-darkblue">HBKU Innovative Start-Up Fund (ISF)</h2>
                                            <p>The HBKU Innovative Start-Up Fund (ISF) aims to support HBKU entrepreneurs with
                                                industry-driven innovations, targeted at increasing the local capacity,
                                                responsiveness, and private enterprise.</p>

                                            <div class="l-article">
                                                <ul>
                                                    <li> <a  href="https://www.hbku.edu.qa/sites/default/files/hbku_innovative_start-up_fund_proposal_submission_guidelines.pdf" target="_blank">Proposal Submission Guidelines</a > </li>
                                                    <li> <a target="_blank" href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Innovative%20Start-up%20Fund%20(ISF)%20Eligibility-Application%20Checklist.pdf">Eligibility and Application (checklist)</a> </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14 m-b-1">
                                                <div class="l-article">
                                                    <div class="video-wrap">
                                                        <img class="" src="../images/fund-img3.png"
                                                            alt="Office of Innovation and Industrial Relations" />
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="col-md-34">
                                            <h3 class="title-md m-b-2 text-darkblue">Industrial Innovation Fund (IIF)</h3>
                                            <p>HBKU Industrial Innovation Fund (IIF) aims to support local, collaborative academia and industry-driven innovations, targeted at increasing the local capacity, responsiveness, and private enterprise.</p>

                                            <div class="l-article m-b-2">
                                            <ul>
                                                <li> <a href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Industrial%20Innovation%20Fund%20(IIF)%20Proposal%20Submission%20Guidelines.pdf" target="_blank">Proposal Submission Guidelines</a> </li>
                                                <li> <a target="_blank" href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office%20-Industrial%20Innovation%20Fund%20(IIF)%20Eligibility-Application%20Checklist.pdf">Eligibility and Application (checklist)</a> </li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14 m-b-1">
                                            <div class="l-article">
                                                <div class="video-wrap">
                                                    <img class="" src="../images/fund-img4.png"
                                                        alt="Office of Innovation and Industrial Relations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-34">
                                            <h3 class="title-md m-b-2 text-darkblue">Social Innovation Fund (SOCIF)</h3>
                                            <p>The HBKU Social Innovation Fund (SOCIF) aims to support local faculty and researchers with industry-driven innovations, targeted at increasing the local capacity, responsiveness, and private enterprise.</p>

                                            <div class="l-article">
                                            <ul>
                                                <li> <a href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Social%20Innovation%20Fund%20(SOCIF)%20Proposal%20Submission%20Guidelines.pdf" target="_blank"> Proposal Submission Guidelines</a> </li>
                                                <li> <a target="_blank" href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Social%20Innovation%20Fund%20(SOCIF)%20Eligibility-Application%20Checklist.pdf"> Eligibility and Application (checklist)</a> </li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="funds__listing m-t-3 m-b-3">
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col-md-14">
                                            <div class="l-article">
                                                <div class="video-wrap">
                                                    <img class="" src="../images/fund-img1.png"
                                                        alt="Office of Innovation and Industrial Relations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-32">
                                            <h3 class="title-md m-b-2 text-darkblue">Student Innovation Fund (SIF)</h3>
                                            <p>The HBKU Student Innovation Fund (SIF) aims to support local students with industry-driven innovations, targeted at increasing the local capacity, responsiveness, and private enterprise.</p>
                                            <p>HBKU students are encouraged to submit proposals on novel solutions, products, business models, and policies to one of the two opportunities available under SIF.</p>

                                            <div class="l-article">
                                            <ul>
                                                <li> <a href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Student%20Innovation%20Fund%20(SIF)%20Proposal%20Submission%20Guidelines.pdf" target="_blank">Proposal Submission Guidelines</a> </li>
                                                <li> <a target="_blank" href="https://portal.qf.org.qa/Documents/HBKU%20IC/IFs%20Guidelines%20and%20Templates/HBKU%20Innovation%20Funds%20Office-Student%20Innovation%20Fund%20(SIF)%20Eligibility-Application%20Checklist.pdf">Eligibility and Application (checklist)</a> </li>
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="m-wrap" data-appear-top-offset="-200">
        <div className="services-section ">
            <div className="container">
                <div className="row">
                    <div className="col-md-48">
                        <div class="mb-5">
                            <h2 class="title-xl m-b-2 text-darkblue">FAQs</h2>
                            <div className="accordion">
                                {FAQData.map(({title, content}) => (
                                <Accordion title={title} content={content}/>
                                ))}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        </>
    )
}

export default FundingBlock;
